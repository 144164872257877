import React from 'react';

type Props = {
  style?: any;
  fill?: string;
};
const SurflineLogo: React.FC<Props> = ({ style, fill }) => (
  <svg width="30" height="40" viewBox="0 0 36 40" fill="none" style={style}>
    <path
      d="M29.1449 19.9977C27.0864 14.1721 15 0 15 0C15 0 2.91194 14.1738 0.853353 20.0011C0.300882 21.565 0 23.248 0 25.0006C0 33.2842 6.71634 40.0006 15 40.0006C23.2837 40.0006 30 33.2842 30 25.0006C30 23.2446 29.6991 21.5616 29.1449 19.9977ZM7.5 27.4977C6.11967 27.4977 4.99943 26.3792 4.99943 24.9972C4.99943 19.4742 9.477 14.9983 14.9983 14.9983C15.8619 14.9983 16.6999 15.1088 17.4989 15.3128C13.1862 16.4228 9.99887 20.3394 9.99887 24.9972C10.0006 26.3792 8.88203 27.4977 7.5 27.4977ZM15 34.9977C14.1364 34.9977 13.2984 34.8889 12.4994 34.6832C16.8121 33.5732 19.9994 29.6583 19.9994 24.9989C19.9994 23.6185 21.118 22.4983 22.5 22.4983C23.8803 22.4983 25.0006 23.6168 25.0006 24.9989C25.0006 30.5219 20.523 34.9977 15 34.9977Z"
      fill={fill || 'white'}
    />
  </svg>
);

export default SurflineLogo;
