import type { SpotReportData, SpotReportViewResponse } from 'types/spot';
import type { Units } from 'types/units';
import { UserFavorites, UserFavoritesResponse } from 'types/userFavorites';
import type { UserState } from 'types/user';
import type { Product } from '../types/products';
import type { TravelZone, TravelSeries, SurfSpot } from '../types/contentful/content-types';
import type {
  OverlayCardData,
  ContentCarouselCardData,
  ProductCardData,
  SpotPreviewCardData,
} from '../types/cards';

export const DEFAULT_IMAGE =
  'https://www.surfline.com/cdn-cgi/image/q=85,f=auto,fit=contain/https://spot-thumbnails.cdn-surfline.com/spots/default/default_1500.jpg';

const formatPrice = (price: number, symbol = '$') => `${symbol}${price.toFixed(2)}`;

export const productCardTransformer = (
  data: Required<Product>,
  suffix: string,
): ProductCardData => ({
  id: data?.id,
  carouselAspectRatio: 1.32,
  category: data?.brand?.name,
  categoryLink: data?.brand?.url,
  link: data?.url,
  linkText: suffix
    ? `${formatPrice(data.prices?.base)} - ${suffix}`
    : `${formatPrice(data.prices?.base)}`,
  title: data?.name,
  images: data?.images?.length
    ? data?.images?.map((image: { url_standard: string }) => image?.url_standard)
    : [DEFAULT_IMAGE],
});

export const zoneCardTransformer = (data: TravelZone): OverlayCardData => ({
  caption: data?.description || undefined,
  link: data?.slug || undefined,
  subtitle: data?.travelRegion?.title || undefined,
  title: data?.title || undefined,
  src: data?.featuredImage?.url || DEFAULT_IMAGE,
  alt: data?.hero?.title || undefined,
});

export const allZonesCardTransformer = (data: TravelZone): OverlayCardData => ({
  caption: `${data?.surfSpotsCollection?.total} spots` || undefined,
  link: data?.slug || undefined,
  subtitle: data?.travelRegion?.title || undefined,
  title: data?.title || undefined,
  src: data?.featuredImage?.url || DEFAULT_IMAGE,
  alt: data?.hero?.title || undefined,
});

export const seriesCardTransformer = (data: TravelSeries): OverlayCardData => ({
  alt: data?.hero?.title || undefined,
  caption: data?.caption || undefined,
  link: data?.link,
  src: data?.featuredImage?.url || DEFAULT_IMAGE,
  subtitle: data?.subtitle || undefined,
  title: data?.title || undefined,
});

export const surfZoneCardTransformer = (data: Required<SurfSpot>): ContentCarouselCardData => ({
  carouselAspectRatio: 0.5625,
  description: data?.description || '',
  id: Number(data?.spotId),
  images: data?.mediaCollection?.items.length
    ? data?.mediaCollection?.items.map((image) => image?.url)
    : [DEFAULT_IMAGE],
  link: data?.link,
  linkText: '',
  linkAs: data?.linkAs,
  subtitle: data?.abilityLevel || '',
  title: data?.title || '',
  type: '',
});

export const spotPreviewCardTransformer = (
  spotReportData: SpotReportData | SpotReportViewResponse,
  units: Units,
) => ({
  id: spotReportData._id,
  title: spotReportData.name,
  geo: {
    lat: spotReportData.lat,
    lon: spotReportData.lon,
  },
  currentWaveHeight: spotReportData.waveHeight,
  units,
  currentConditions: spotReportData.conditions.value,
  camera: spotReportData.cameras?.[0],
  insightsCameraId: spotReportData.insightsCameraId ? spotReportData.insightsCameraId : false,
});

export const userFavouritesToSpotPreviewCardsTransformer = (
  data: UserFavoritesResponse,
): SpotPreviewCardData[] => {
  if (data?.data?.favorites) {
    return data.data.favorites.map((fav) => spotPreviewCardTransformer(fav, data.associated.units));
  }
  return [];
};

export const userToFavouritesResponseTransformer = (
  user: UserState | null,
): UserFavoritesResponse => ({
  data: {
    favorites: (user?.favorites as unknown as UserFavorites) || [],
  },
  associated: {
    units: {
      temperature: user?.settings.units.temperature || '',
      tideHeight: user?.settings.units.tideHeight || '',
      swellHeight: user?.settings.units.swellHeight || '',
      waveHeight: user?.settings.units.surfHeight || '',
      windSpeed: user?.settings.units.windSpeed || '',
    },
  },
});
