import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box, Button, Skeleton, Typography, IconButton, useMediaQuery } from '@mui/material';
import { round } from 'lodash';
import cx from 'classnames';
import useSWR, { mutate } from 'swr';
import { nrNoticeError, doExternalFetch } from '@surfline/web-common';
import Link from 'next/link';
import ErrorMessage from 'components/ErrorMessage';
import type { CtaButtonProps, ContentCarouselProps } from 'types/contentCarousel';
import {
  useMaxWidthDesktop,
  useMaxWidthDesktopSmall,
  useMaxWidthMobileLarge,
  useMaxWidthTablet,
  useMinWidthDesktopLarge,
} from 'hooks/useMediaQueries';
import { ChevronLeft, ChevronRight } from '../Icons';
import Styles from './ContentCarousel.module.scss';
import type { ContentCarouselCardData, OverlayCardData } from '../../types/cards';
import SurflineLogo from '../SurflineLogo';

const getCtaButtonClassNames = (startIcon?: boolean, isDark?: boolean, mobile?: boolean) =>
  cx({
    carouselCallToActionButton: true,
    'carouselCallToActionButton--light': !isDark,
    'carouselCallToActionButton--dark': isDark,
    [Styles.callToActionButtonMobile]: mobile,
    [Styles.callToActionButton]: true,
    [Styles.callToActionButtonWithIcon]: startIcon,
  });

const CtaButton: React.FC<CtaButtonProps> = ({
  isExternal = false,
  link,
  linkAs,
  text,
  mobile,
  isDark,
  onClickCallback,
  startIcon,
  isReferrer,
}) => {
  const onClickButtonHandler = () => {
    if (onClickCallback) onClickCallback();
  };

  const renderButton = () => (
    <Button
      disableRipple
      variant={mobile ? 'text' : 'secondary'}
      color={isDark && !mobile ? 'dark' : 'primary'}
      style={mobile ? { color: isDark ? '#FFFFFF' : '#424242' } : {}}
      className={getCtaButtonClassNames(startIcon, isDark, mobile)}
      data-testid="sl-content-carousel-cta-button"
      onClick={onClickButtonHandler}
      startIcon={startIcon && <SurflineLogo />}
      href={isExternal ? link : ''}
      referrerPolicy={isReferrer ? 'no-referrer-when-downgrade' : 'strict-origin-when-cross-origin'}
    >
      {text}
    </Button>
  );

  return isExternal ? (
    renderButton()
  ) : (
    <Link href={link} as={linkAs} shallow data-testid="sl-content-carousel-call-to-action-button">
      {renderButton()}
    </Link>
  );
};

const ContentCarousel: React.FC<ContentCarouselProps> = ({
  carouselItem,
  className,
  reliesOnAspectRatio = true,
  colors = {
    bg: '#171717',
    skeletonBg: '#2b2a2a',
    skeletonItem: '#333333',
    headerText: 'white',
    headerSubtitle: 'white',
    headerButton: 'white',
    errorText: 'white',
    noItemsBg: '#3a4972',
    noItemsText: 'white',
  },
  useSLSectionPadding = false,
  dataTest,
  bleedOnDesktop = false,
  dataTransform,
  fetchConfig = null,
  isProductCarousel = false,
  itemAspectRatio = 1,
  minItemWidth,
  noItemsMessage = null,
  numberOfitems = 3,
  rawData = null,
  screenConfig = { small: 2, medium: 3, mobile: 2 },
  showLogo = false,
  showPageCount = false,
  renderOnError = false,
  style,
  subtitle,
  title,
  gutter = 16,
  callToActionButton,
  carouselItemCustomClass,
  arrowButtonCallbacks,
  shouldMutateData,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [translation, setTranslation] = useState(0);
  const [noItems, setNoItems] = useState(false);
  // using large desktop width from quiver _media.scss
  const desktopLargeWidth = useMinWidthDesktopLarge();
  const desktopWidth = useMaxWidthDesktop();
  const tabletWidth = useMaxWidthDesktopSmall();
  const mobileLarge = useMaxWidthTablet();
  const mobileMedium = useMaxWidthMobileLarge();
  const mobileSmall = useMediaQuery('(max-width:350px)');
  const isMobile = mobileLarge || mobileMedium || mobileSmall;
  const defaultMinItemWidth = isProductCarousel ? '300px' : '340px';

  const mobileScrollWrapper = useRef<HTMLDivElement>(null);
  const contentWrapper = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  /** Internally fetch data with useSWR so that we can show skeletons when fetched */
  const { data, error } = useSWR(
    fetchConfig?.endpoint,
    fetchConfig?.fetch || doExternalFetch,
    fetchConfig?.options || {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const rawCarouselData = useMemo(
    () => (data && dataTransform && dataTransform(data)) || rawData || [],
    [data, dataTransform, rawData],
  );
  const carouselData = useMemo(
    () => (Array.isArray(rawCarouselData) ? rawCarouselData.filter(Boolean) : []),
    [rawCarouselData],
  );

  useEffect(() => {
    if (data && shouldMutateData) {
      mutate(
        fetchConfig?.endpoint,
        fetchConfig?.fetch || doExternalFetch,
        fetchConfig?.options || {
          revalidateOnFocus: false,
          revalidateOnMount: true,
          revalidateOnReconnect: false,
          refreshWhenOffline: false,
          refreshWhenHidden: false,
          refreshInterval: 0,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMutateData]); // We should only mutate the data when this prop changes

  /** Special case for when only 1 card is returned */
  const singleCardViewCount = 3;
  const itemsDisplayed = {
    mobile:
      carouselData?.length === 1 ? singleCardViewCount : screenConfig?.mobile || numberOfitems,
    mobileMedium:
      carouselData?.length === 1
        ? singleCardViewCount
        : screenConfig?.mobileMedium || numberOfitems,
    mobileLarge:
      carouselData?.length === 1 ? singleCardViewCount : screenConfig?.mobileLarge || numberOfitems,
    tablet: carouselData?.length === 1 ? singleCardViewCount : screenConfig?.small || numberOfitems,
    desktop:
      carouselData?.length === 1 ? singleCardViewCount : screenConfig?.medium || numberOfitems,
    desktopLarge:
      carouselData?.length === 1
        ? singleCardViewCount
        : screenConfig?.large || screenConfig?.medium || numberOfitems,
  };

  const calcualtedItemsDisplayed = useMemo(() => {
    /** Special case for when only 1 card is returned */
    if (carouselData?.length === 1) return 3;

    if (mobileSmall || mobileMedium || mobileLarge) return screenConfig?.mobile;
    if (tabletWidth) return screenConfig?.small;
    if (desktopWidth) return screenConfig?.medium;
    if (desktopLargeWidth) return screenConfig?.large || screenConfig?.medium;
    return numberOfitems;
  }, [
    carouselData?.length,
    mobileSmall,
    mobileMedium,
    mobileLarge,
    screenConfig?.mobile,
    screenConfig?.small,
    screenConfig?.medium,
    screenConfig?.large,
    tabletWidth,
    desktopWidth,
    desktopLargeWidth,
    numberOfitems,
  ]);

  const cardWidths = {
    mobile:
      carouselData?.length === 1
        ? '66.66%'
        : `calc(${round(100 / itemsDisplayed.mobile, 2)}% - ${gutter}px)`,
    mobileMedium:
      carouselData?.length === 1
        ? '66.66%'
        : `calc(${round(100 / itemsDisplayed.mobileMedium, 2)}% - ${gutter}px)`,
    mobileLarge:
      carouselData?.length === 1
        ? '66.66%'
        : `calc(${round(100 / itemsDisplayed.mobileLarge, 2)}% - ${gutter}px)`,
    tablet: `calc(${round(100 / itemsDisplayed.tablet, 2)}% - ${gutter}px)`,
    desktop: `calc(${round(100 / itemsDisplayed.desktop, 2)}% - ${gutter}px)`,
    desktopLarge: `calc(${round(100 / itemsDisplayed.desktopLarge, 2)}% - ${gutter}px)`,
  };

  const pagesLabel = Math.ceil(carouselData.length / calcualtedItemsDisplayed);
  const currentPage = (translation / 100) * -1 + 1;

  /** Calculate if carousel buttons are disabled based off of total transition */
  const disableLeftButton = !carouselData?.length || (!mobileLarge && translation === 0);
  const disableRightButton =
    !carouselData?.length ||
    (!mobileLarge && translation <= (carouselData.length / calcualtedItemsDisplayed) * -100 + 100);

  /** Set transofrmX back to 0 when the cards change in display and size like in AirBnb */
  useEffect(() => {
    setTranslation(0);
  }, [calcualtedItemsDisplayed, error]);

  /** Scroll back to beginning when switching between mobile and non-mobile */
  useEffect(() => {
    mobileScrollWrapper?.current?.scrollTo(0, 0);
  }, [mobileLarge, mobileMedium, mobileSmall]);

  /** Update state to handle messaging when no items found */
  useEffect(() => {
    if (noItemsMessage && data && !carouselData?.length && !error) setNoItems(true);
  }, [noItemsMessage, carouselData, data, error]);

  if (error) {
    nrNoticeError(error, {});
  }

  /** If the data fetched comes back with an error from sWR then do not render the carousel */
  if (error && !renderOnError) {
    return null;
  }

  const headerContainerClasses = cx({
    'sl-section-container': useSLSectionPadding,
  });

  const headerClasses = cx({
    [Styles.header]: true,
    [Styles.headerLegacyPadding]: !useSLSectionPadding,
  });

  const headerInnerClasses = cx({
    [Styles.headerInner]: true,
    [Styles.headerInnerProductCarousel]: isProductCarousel,
  });

  const carouselSectionClasses = cx({
    [Styles.carouselSection]: true,
    'sl-section-full-bleed': useSLSectionPadding && bleedOnDesktop,
  });

  const carouselClasses = cx({
    [Styles.carousel]: true,
    [Styles.carouselLegacyPadding]: !useSLSectionPadding,
    [Styles.carouselScrollBehaviour]: useSLSectionPadding,
    'sl-section-full-bleed': useSLSectionPadding && (isMobile || bleedOnDesktop),
  });

  const carouselContentClasses = cx({
    [Styles.carouselContent]: true,
    [Styles.carouselScrollBehaviour]: true,
    [Styles.carouselContentLegacyMargin]: !useSLSectionPadding,
  });

  const carouselInnerClasses = cx({
    [Styles.carouselInner]: true,
    [Styles.carouselInnerLegacyPadding]: !useSLSectionPadding,
    'sl-section-container': useSLSectionPadding,
  });

  const legacyScrollOverflowSettings = {
    carouselMobileWrap: mobileLarge ? 'auto' : 'hidden',
    carouselContent: mobileLarge ? 'auto' : 'visible',
  };

  const onClickLeft = () => {
    setTranslation(translation + 100);
    arrowButtonCallbacks?.left();
  };

  const onClickRight = () => {
    setTranslation(translation - 100);
    arrowButtonCallbacks?.right();
  };

  return (
    <section className={carouselSectionClasses}>
      <div
        ref={carouselRef}
        data-testid={dataTest || 'sl-content-carousel-wrapper'}
        className={cx(Styles.carouselWrap, className, {
          [Styles.noAspectRatio]: !reliesOnAspectRatio,
        })}
        style={{
          // @ts-ignore
          '--sl-skeleton-bg-color': colors.skeletonBg,
          '--sl-skeleton-item-color': colors.skeletonItem,
          '--sl-header-title-text-color': colors.headerText,
          '--sl-header-subtitle-text-color': colors.headerSubtitle
            ? colors.headerSubtitle
            : colors.headerText,
          '--sl-error-text-color': colors.errorText,
          '--sl-header-button-color': colors.headerButton,
          '--sl-card-aspect-ratio': `${itemAspectRatio * 100}%`,
          '--sl-card-gutter': `${gutter}px`,
          '--sl-card-width-mobileSmall': cardWidths.mobile,
          '--sl-card-width-mobileMedium': cardWidths.mobileMedium,
          '--sl-card-width-mobileLarge': cardWidths.mobileLarge,
          '--sl-card-width-tablet': cardWidths.tablet,
          '--sl-card-width-desktop': cardWidths.desktop,
          '--sl-card-width-desktopLarge': cardWidths.desktopLarge,
          '--sl-cards-total-count': carouselData.length,
          '--carousel-item-min-width':
            isMobile && minItemWidth ? minItemWidth : defaultMinItemWidth,
          background: colors.bg,
          ...style,
        }}
      >
        <div className={headerContainerClasses}>
          <div className={headerClasses}>
            <div className={headerInnerClasses}>
              <span className={Styles.titleWrap}>
                {showLogo ? (
                  <SurflineLogo
                    style={{ margin: '5px 0 0 -5px', height: '21px' }}
                    fill={colors.headerText}
                  />
                ) : null}
                {title ? (
                  <Typography variant="h2" className={Styles.title}>
                    {title}
                  </Typography>
                ) : null}
              </span>
              {subtitle ? (
                <Typography variant="body" className={Styles.subtitle}>
                  {subtitle}
                </Typography>
              ) : null}
            </div>
            {!mobileLarge ? (
              <div className={cx(Styles.headerButtons, 'header-buttons-wrapper')}>
                {showPageCount && !!carouselData?.length && (
                  <Typography variant="body" className={Styles.pageCount}>
                    Page {currentPage} of {pagesLabel}
                  </Typography>
                )}
                {callToActionButton && !noItems && <CtaButton {...callToActionButton} />}
                {!error && !!carouselData?.length && (
                  <>
                    <IconButton
                      className={Styles.headerButton}
                      disableRipple
                      data-testid="sl-content-carousel-previous-button"
                      onClick={onClickLeft}
                      disabled={disableLeftButton}
                    >
                      <ChevronLeft />
                    </IconButton>
                    <IconButton
                      disableRipple
                      style={{ paddingRight: 0 }}
                      className={Styles.headerButton}
                      data-testid="sl-content-carousel-next-button"
                      onClick={onClickRight}
                      disabled={disableRightButton}
                    >
                      <ChevronRight />
                    </IconButton>
                  </>
                )}
              </div>
            ) : (
              <div className={Styles.mobileHeaderButtons}>
                {callToActionButton && <CtaButton {...callToActionButton} mobile />}
              </div>
            )}
          </div>
        </div>

        {!!error && !!renderOnError && (
          <div
            className={Styles.errorWrapper}
            data-testid="content-carousel-error"
            style={{
              background: colors.bg,
            }}
          >
            <ErrorMessage className={Styles.errorMessage} />
          </div>
        )}

        {noItems && (
          <Box className={Styles.noItems}>
            {noItemsMessage?.message && (
              <Typography variant="title3" style={{ color: colors.noItemsText }}>
                {noItemsMessage?.message}
              </Typography>
            )}
            {noItemsMessage?.ctaUrl && (
              <Button
                className="sl-registration__fb__submit"
                color="dark"
                href={noItemsMessage.ctaUrl}
                size="medium"
                startIcon={
                  <SurflineLogo
                    style={{ width: '18px', paddingRight: '5px' }}
                    fill={colors.headerText}
                  />
                }
                target="_blank"
                variant="primary"
                referrerPolicy="no-referrer-when-downgrade"
              >
                {noItemsMessage?.ctaCopy || 'View All'}
              </Button>
            )}
          </Box>
        )}

        {!error && !noItems && (
          <div
            className={carouselClasses}
            style={{
              overflow: mobileLarge ? 'auto' : 'hidden',
            }}
          >
            <div className={carouselInnerClasses}>
              <div
                ref={mobileScrollWrapper}
                className={Styles.carouselMobileWrap}
                style={{
                  overflow:
                    useSLSectionPadding && (bleedOnDesktop || mobileLarge)
                      ? 'visible'
                      : legacyScrollOverflowSettings.carouselMobileWrap,
                }}
              >
                <div
                  className={carouselContentClasses}
                  data-testid="sl-content-carousel-content-wrapper"
                  ref={contentWrapper}
                  style={{
                    transform: `translateX(${translation}%)`,
                    overflow:
                      useSLSectionPadding && (bleedOnDesktop || mobileLarge)
                        ? 'visible'
                        : legacyScrollOverflowSettings.carouselContent,
                  }}
                >
                  {carouselData?.length
                    ? carouselData?.map(
                        (item: ContentCarouselCardData | OverlayCardData, index: number) => (
                          <div
                            key={`${index + 1}`}
                            ref={itemRef}
                            className={cx(Styles.carouselItem, {
                              [Styles.carouselItemProduct]: isProductCarousel,
                              [carouselItemCustomClass || '']: true,
                            })}
                            data-testid="sl-content-carousel-card-wrapper"
                          >
                            <div className={Styles.carouselItemInner}>
                              {carouselItem(item, index, carouselRef)}
                            </div>
                          </div>
                        ),
                      )
                    : new Array(numberOfitems).fill({}).map((_, index) => (
                        <div
                          key={`skeleton-${index + 1}`}
                          ref={itemRef}
                          className={Styles.carouselItem}
                          data-testid="sl-content-carousel-card-wrapper"
                        >
                          <div
                            className={Styles.carouselItemInner}
                            data-testid="sl-content-carousel-skeleton-card"
                          >
                            <Box className={Styles.skeleton}>
                              <Skeleton className={Styles.skeletonItemBig} variant="rectangular" />
                              <Skeleton className={Styles.skeletonItem} />
                              <Skeleton className={Styles.skeletonItem} width="60%" />
                            </Box>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentCarousel;
